import './conceptos.scss';
import React, { useEffect, useState } from 'react';
import Concepto from '../../organism/concepto/concepto';
import { ClientContext } from '../../../provider/cliente';
import { removePropertyByKey, isInvoiceRequestDisable, getValueDropdown } from '../../../utils/utils';
import { BusinessFilialService } from '../../../services/listarNegociosService'
import {brandsService, getBrandsByClientSubsidiary} from '../../../services/listarMarcasService';
import { OpportunitiesFilialService } from '../../../services/listarOportunidadesService'
import { getFiliales } from '../../../services/parametersService';
import { TAX_REGIME_IDS } from '../../../utils/constans';
import { formatValToCurrency } from '../../../utils/utils';
import { FacturaContext } from '../../../provider/factura';
import { useTranslation } from 'react-i18next';
import { PdsDropdown, PdsDropdownOption } from '@pragma-dev-kit/pragma-ds-react';
import ConceptosConstants from '../../../constants/conceptos-constants';

const Conceptos = (props) => {
    const constants = ConceptosConstants;
    const [dataFactura, setDataFactura] = React.useContext(FacturaContext);
    const { dataClient } = React.useContext(ClientContext);
    const [checkConcept, setCheckConcept] = useState(constants.FALSE);
    const [dataBusiness, setDataBusiness] = React.useState([]);
    const [dataBrands, setDataBrands] = React.useState([]);
    const [dataOpportunities, setDataOpportunities] = React.useState([]);
    const [dataFiliales, setDataFiliales] = React.useState([]);
    const [dataConceptos, setDataConceptos] = React.useState({ data: [{ centroCostoDTO: [{ valor: constants.NUMBERS.ZERO }] }], totalConceptos: constants.NUMBERS.ZERO });
    const dataCoinType = [{ code: constants.NUMBERS.ONE, name: constants.CURRENCY.COP }, { code: constants.NUMBERS.TWO, name: constants.CURRENCY.USD }];
    const [t] = useTranslation(constants.GLOBAL);
    const canShowDatesFields = dataFiliales.find(({ name }) => name === constants.PIRANI_COLOMBIA_KEY)?.code === dataFactura.filialId

    React.useEffect(() => {

        async function queryBrands() {
            await brandsService().then(response => {
                let brandsArray = [];
                response.forEach(brand => {
                    brandsArray.push({ code: brand.code, name: brand.name });
                });   
                setDataBrands(brandsArray);
            }).catch(error => {
                setDataBrands([]);
            })
        }

        async function queryFiliales() {
            await getFiliales().then(response => {
                setDataFiliales(response);
            }).catch(error => {
                setDataFiliales([]);
            })
        }

        queryFiliales();
        queryBrands();
    }, []);

    const activeBrands = () => {
        const activeB = [];
        dataBrands.forEach(element => {
            if(element.active) activeB.push(element);
        })
        return activeB;
    }

    useEffect(() => {
        if (dataClient.clienteId && dataFactura.filialId) {
            async function querySubsidiaryByParams() {
                await getBrandsByClientSubsidiary(dataClient.clienteId, dataFactura.filialId)
                    .then(response => {
                        setDataBrands(response);
                    }).catch(error => {
                        setDataBrands([]);
                    })
            }
            querySubsidiaryByParams();
        }
    }, [dataClient.clienteId, dataFactura.filialId])

    useEffect( () => {
        if(dataFactura.filialId){
            const data = { filialId: dataFactura.filialId };
            async function queryBusinessBySubsidiar() {
                await BusinessFilialService(data)
                .then(response => {
                    if (response.status === constants.STATUS.SUCCESS) {
                        setDataBusiness(response.datas);
                    }
                }).catch(error => {
                    setDataBusiness([]);
                })
            }
            queryBusinessBySubsidiar();
        }
    }, [dataFactura.filialId])

    useEffect( () => {
        if(dataFactura.filialId){
            const data = { filialId: dataFactura.filialId };
            async function queryOpportunitiessBySubsidiar(){
                await OpportunitiesFilialService(data)
                .then(response => {
                    if (response.status === constants.STATUS.SUCCESS) {
                        setDataOpportunities(response.datas);
                    }
                }).catch(error => {
                    setDataOpportunities([]);
                })
            }
            queryOpportunitiessBySubsidiar();
        }
    }, [dataFactura.filialId])


    React.useEffect(() => {
        if (dataFactura.facSolicitudId && dataFactura.conceptos.length) {
            dataConceptos.data = dataFactura.conceptos;
            dataConceptos.totalConceptos = calculateTotalInvoiceValue(); 
            setDataConceptos({ ...dataConceptos });
        }

        if(props.isDuplicate && !checkConcept){
            handlerDataFactura();
            setCheckConcept(true);
        }

        if (dataFactura.clienteId === constants.NUMBERS.MINUS_ONE && dataFactura.monedaId === constants.NUMBERS.TWO) {
            dataFactura.monedaId = constants.NUMBERS.ONE;
            setDataFactura({
                type: constants.ON_CHANGE_CONCEPT,
                payload: { ...dataFactura }
            });
        }

    }, [dataFactura]);

    React.useEffect(() => {
        if (dataClient.clienteId) {
            if (dataClient.clasificacionTributariaId === TAX_REGIME_IDS.SIN_REGIMEN) {
                dataFactura.monedaId = constants.NUMBERS.TWO;
                setDataFactura({
                    type: constants.ON_CHANGE_CONCEPT,
                    payload: { ...dataFactura }
                });
            }
        }
    }, [dataClient]);

    const addConcept = () => {
        if(dataFactura.facSolicitudId){
            dataConceptos.data.push({centroCostoDTO: [{ valor: constants.NUMBERS.ZERO }], facSolicitudId : dataFactura.facSolicitudId});
        }else{
            dataConceptos.data.push({centroCostoDTO: [{ valor: constants.NUMBERS.ZERO }]});
        }
        setDataConceptos({ data: [...dataConceptos.data], totalConceptos: dataConceptos.totalConceptos });
        handlerDataFactura();
    };

    const removeConcept = (index) => {
        if (dataConceptos.data.length > constants.NUMBERS.ONE) {
            updateTotalValueConcept();
            dataConceptos.data = dataConceptos.data.filter((item, i) => { return i !== index });
            dataConceptos.totalConceptos = calculateTotalInvoiceValue();
            setDataConceptos({ ...dataConceptos });
            handlerDataFactura();
        }
    };

    const handlerDataFactura = () => {

        let conceptoData = [...dataConceptos.data];
        conceptoData.forEach((element, index) => {
            conceptoData[index] = removePropertyByKey(element, constants.COST_CENTER_TOTAL);
            if(props.isDuplicate){
                delete element.facSolicitudId;
                delete element.facConceptoId;
                element.centroCostoDTO.forEach(function(centroCosto){ delete centroCosto.facConceptoId; delete centroCosto.centroCostoId; });
                conceptoData[index] = element;
            }
        });
        dataFactura.conceptos = conceptoData;
        setDataFactura({
            type: constants.ON_CHANGE_CONCEPT,
            payload: { ...dataFactura }
        });
    };

    const changeMonedaId = (event) => {
        const monedaId =+  dataCoinType.find(coin => coin.name === event.detail)?.code; 
        
        dataFactura.monedaId = parseInt(event.target.value);
        setDataFactura({
            type: constants.ON_CHANGE_CONCEPT,
            payload: { ...dataFactura }
        });
    };

    const handleOnChangeConcept = (index, concept) => {
        dataConceptos.data[index] = concept;
        dataConceptos.totalConceptos = calculateTotalInvoiceValue();
        setDataConceptos({ ...dataConceptos });
        handlerDataFactura();
    };

    const calculateTotalInvoiceValue = () => {
        let total = constants.NUMBERS.ZERO;
        for (let concepto of dataConceptos.data) {
            if (concepto.costCenterTotal) {
                let conceptoValor = concepto.costCenterTotal;
                total += parseFloat(conceptoValor || constants.NUMBERS.ZERO);
            } else {
                let conceptoValor = concepto.centroCostoDTO.map(getCenterCostValues).reduce((acumulator, current) => acumulator + current);
                total += parseFloat(conceptoValor || constants.NUMBERS.ZERO);
            }
        }
        return total === constants.NUMBERS.ZERO ? total : total.toFixed(constants.NUMBERS.TWO);
    };

    const getCenterCostValues = (centerCost) => {
        return centerCost.valor;
    };
    const updateTotalInvoiceValue = (index, number, total) => {
        dataConceptos.data[number].centroCostoDTO = dataConceptos.data[number].centroCostoDTO.filter((item, i) => { return i !== index });

        let actual = dataConceptos.totalConceptos;

        let nuevoTotal = actual - total;
        setDataConceptos({ ...dataConceptos, totalConceptos: nuevoTotal });
    };

    const updateTotalValueConcept = () => {
        dataConceptos.data.forEach(element => {
            let valor = constants.NUMBERS.ZERO;
            element.centroCostoDTO.forEach(centroCosto => {
                valor += parseFloat(centroCosto.valor);
            });
            element.costCenterTotal = valor;
        });
    };

    const changeSubsidiary = (event) => {
        let filialId =+ dataFiliales.find(filial => filial.name === event.detail).code;
        dataFactura.filialId = parseInt(filialId);
        setDataFactura({
            type: constants.ON_CHANGE_CONCEPT,
            payload: { ...dataFactura }
        });
    };



    let conceptMap = dataConceptos.data.map(function (item, i) {
        return (
            <Concepto
                key={i}
                number={i}
                said={i}
                removeConcept={removeConcept.bind(this, i)}
                onChangeConcept={handleOnChangeConcept}
                dataBusiness={dataBusiness}
                dataBrands={dataFactura.facSolicitudId ? dataBrands : activeBrands()}
                dataOpportunities={dataOpportunities}
                updateTotalInvoiceValue={updateTotalInvoiceValue}
                dataConcepto={item}
                brandDisabled={props.brandDisable}
                className="individualConcepto"
                canShowDatesFields={canShowDatesFields}
                changeClient={props.changeClient}
            />);
    }
    );

    return (
        <div className="ContentConcepts">
            <div className="conceptsHeader">
                <div className="titleConceptos">
                    <h3>{t('invoices.createInvoice.concepts')}</h3>
                </div>
                <div className="concept-fields">
                    <PdsDropdown
                        label={t('invoices.createInvoice.subsidiary')}
                        onValueChange={ changeSubsidiary }
                        value={dataFactura.filialId}
                        disabled={isInvoiceRequestDisable(dataFactura)}
                        placeholder={getValueDropdown('code', dataFactura.filialId, dataFiliales) || t('transversal.selectOption')}
                        required={isInvoiceRequestDisable(dataFactura) ? true : false}
                    >
                        {dataFiliales.map(item => (
                            <PdsDropdownOption
                                theme='dark'
                                value={item.name}
                                key={item.code}
                            >
                                {item.name}
                            </PdsDropdownOption>
                        ))}
                    </PdsDropdown>
                </div>
                <div className="concept-fields-coin">
                    <PdsDropdown
                        label={t('invoices.createInvoice.typeCurrency')}
                        onValueChange={changeMonedaId}
                        value={dataFactura.monedaId}
                        disabled={isInvoiceRequestDisable(dataFactura) || dataClient.clasificacionTributariaId === TAX_REGIME_IDS.SIN_REGIMEN}
                        placeholder={getValueDropdown('code', dataFactura.monedaId, dataCoinType) || t('transversal.selectOption')}
                        required={isInvoiceRequestDisable(dataFactura) ? true : false}
                    >
                        {dataCoinType.map(item => (
                            <PdsDropdownOption
                                theme='dark'
                                value={item.name}
                                key={item.code}
                            >
                                {item.name}
                            </PdsDropdownOption>
                        ))}
                    </PdsDropdown>
                </div>

            </div>
            {conceptMap}
            {!isInvoiceRequestDisable(dataFactura) ? <button className="concept__add" type='button' onClick={addConcept.bind(this)}>
                {t('invoices.createInvoice.addConcept')}
            </button>
                : " "}
            <div className="total totalFinal">
                <h3>{t('invoices.createInvoice.totalInvoice')}:</h3>
                <h3>{'$' + formatValToCurrency(constants.NUMBERS.ONE, dataConceptos.totalConceptos)} </h3>
            </div>

        </div>
    );
}

export default Conceptos;
